/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
	ConfirmationService,
	MenuItem,
	MessageService,
	SelectItem
} from 'primeng/api';
import {RfiLookupRequestService} from 'src/app/services/rfi/rfi-lookup-request.service';
import {RfiCostRequestService} from 'src/app/services/rfi/rfi-cost-request.service';
import {RfiQuestionLookupRequestService} from 'src/app/services/rfi/rfi-question-lookup-request.service';
import moment from 'moment';
import {RfiThresholdRequestService} from 'src/app/services/rfi/rfi-threshold-request.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ManualRfiRequestService} from 'src/app/services/rfi/manual-rfi-request.service';
import {RfiRequestService} from 'src/app/services/rfi/rfi-request.service';
import {ManualRfiCostRequestService} from 'src/app/services/rfi/manua-rfi-cost-request.service';
import {ManualRfiToolingRequestService} from 'src/app/services/rfi/manual-rfi-tooling-request.service';
import {ManualRfiEDTCostService} from 'src/app/services/rfi/manual-rfi-edt-cost.service';
import {ApsService} from '../../services/aps.service';
import {RfiHistoryRequestService} from 'src/app/services/rfi/rfi-history-request.service';
import {PdfGenerator} from './pdf-generator';

@Component({
	selector: 'create-manual-rfi-request',
	templateUrl: './create-manual-rfi-request.component.html',
	styleUrls: ['./create-manual-rfi-request.component.css'],
	providers: [MessageService, ConfirmationService, PdfGenerator]
})
export class CreateManualRfiRequestComponent implements OnInit {
	yesNoType: SelectItem[] = [
		{title: 'Yes', label: 'Yes', value: 'Yes', icon: 'fa fa-check'},
		{title: 'No', label: 'No', value: 'No', icon: 'fa fa-times'}
	];
	modeType: SelectItem[] = [
		/*{title: 'View', label: 'View', value: 'View', icon: 'fa fa-eye'}*/
	];
	selectedMode = 'View';
	cols: any[];
	toolingRecords: any[] = [];
	toolings: any[] = [];
	tooling = {};
	selectedTooling = {};
	displayDialog = false;
	detailDialog = false;
	dialogDetails = null;
	newTooling = false;
	toolingTotal = 0;
	subComTotPrevRFI = 0;
	subComTotCurRFI = 0;
	subMatTotPrevRFI = 0;
	subMatTotCurRFI = 0;
	prevSubComTotCurRFI = 0;
	prevSubMatTotCurRFI = 0;
	prevSubComTotPrevRFI = 0;
	prevSubMatTotPrevRFI = 0;
	prevToolingCost = 0;
	prevLumpSumCost = 0;
	rfiHistory = {};
	rfiHistoryList = [];
	costChange = false;
	isValidBuyer = false;
	isValidDnR = false;
	isValidCostEstimator = false;
	private mode: string;
	buyerSupplier: string;
	private rfiId: string;
	rfiRequest: any = {};
	private materialCosts = [];
	private edtCosts = [];
	private clonedMaterial: {[s: string]: any} = {};
	private lookups = [];
	private questionLookups = [];
	private totPrevRFI = 0;
	private totCurvRFI = 0;
	private isLoader = false;
	private rfiQuestions = [];
	private clonedRfiQuestion: {[s: string]: any} = {};
	private tabIndex = 0;
	private rfiRequestForm: FormGroup;
	beginRFI = false;
	showCalculation = false;
	private reSubmitRFI = false;
	private submitToPDRFI = false;
	private commercialCosts = [];
	private clonedCommercial: {[s: string]: any} = {};
	private deltaRFIHeader =
		'Delta \n\r(Positive[+] Delta = Increase)\n\r(Negative[-] Delta = Decrease)';
	private deltaLumpSumHeader =
		'Delta \n\r(Positive[+] Delta = Increase)\n\r(Negative[-] Delta = Decrease)';
	private costtool =
		'Delta \n\r(Positive[+] Delta = Increase)\n\r(Negative[-] Delta = Decrease)';
	private timer;
	negativeTimer = false;
	statusColor = 'black';
	private status;
	private program;
	breadCrumItems: MenuItem[];
	private isUnselectClick = false;
	private isPdEngg = false;
	private searchRfi = 'false';
	private back = 'false';
	private new = 'false';
	private isToolingWholeOption = 'true';
	private isProdToolingWarn = false;
	private rfiList = [];
	private rfiStr = {label: '', value: ''};
	private rfiOrgRequest: any = {};
	private questionCols = ['name'];
	private maxChars = 1024;
	private isResCurEditable = null;
	private isSiteCodeEditable = null;
	private questions = [
		{name: 'Go to WERS on Web(link: www.wers.ford.com)'},
		{
			name: '1) Have your concern number ready and add to concern number field'
		},
		{
			name: "2) Select the 'Add 12 Questions' tab and select your activity'"
		},
		{name: '3) Complete 12 questions'},
		{name: '4) Select “Add 12 Questions” button'},
		{
			name: '5) 12 Questions will then show on “Resolution” for selected activity'
		},
		{name: 'For Reference: Link'}
	];
	private prevRFILable = 'Previous RFI Cost\n\r';
	private prevLumpSumRFILable = 'Previous RFI Lump Sum ED&T\n\r';
	private supplierRFILumpSum = 'RFI Estimate\n\r';
	private curretRFIHeader = 'RFI Estimate\n\r';
	private toolingRFIHeader = 'Supplier’s New Total Tooling \n\r';
	private prevToolingRFILable = 'Previous RFI Production Tooling\n\r';
	private psaList = [];
	private psa1 = {label: '', value: ''};
	private timerList = [];
	private timerOption = {label: '', value: ''};
	private psa;
	isSubmit;
	private typeOfUser;
	private validationMessage = 'Buyer CDSID is required';
	private validationMessagePD = 'D&R CDSID is required';
	isBuyerId = false;
	isPDId = false;
	isCostEstimatorId = false;
	private buddyBuyerMap = {};
	private costEstimatorMap = {};
	private buddyEnginnerMap = {};
	private additionTimerRqstMsg;
	private manualRFIType = 'true';
	interanlCostDetailsEdit = false;
	showInteranlCostDetailsEdit = true;

	constructor(
		private activatedRoute: ActivatedRoute,
		private manualRfiRequestService: ManualRfiRequestService,
		private rfiRequestService: RfiRequestService,
		private rfiToolingRequestService: ManualRfiToolingRequestService,
		private rfiLookupRequestService: RfiLookupRequestService,
		private rfiCostRequestService: RfiCostRequestService,
		private manualRfiCostRequestService: ManualRfiCostRequestService,
		private rfiThreasholdService: RfiThresholdRequestService,
		private router: Router,
		private rfiQuestionLookupRequestService: RfiQuestionLookupRequestService,
		private rfiEDTCostService: ManualRfiEDTCostService,
		private historyService: RfiHistoryRequestService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		private fb: FormBuilder,
		private pdfGenerator: PdfGenerator,
		private _sanitizer: DomSanitizer,
		private apsService: ApsService
	) {
		this.cols = [
			{field: 'name', header: 'Tooling Item'},
			{field: 'cost', header: 'Cost'},
			{field: 'additionalInfo', header: 'Additional Info'}
		];
		this.timerList = [
			{label: 'No Timer', value: '0'},
			{label: '24 Hours', value: '1'},
			{label: '48 Hours', value: '2'},
			{label: '72 Hours', value: '3'}
		];

		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));

		this.retrieveRouteParameter();
		if (
			apsRoles &&
			((this.buyerSupplier === 'supplier' &&
				apsRoles.includes('EDCM_UPDATE')) ||
				(this.buyerSupplier === 'buyer' &&
					apsRoles.includes('PD_Engineer')) ||
				(this.buyerSupplier === 'buyer' &&
					apsRoles.includes('Buyer')) ||
				(this.buyerSupplier === 'buyer' &&
					apsRoles.includes('CostEstimator')) ||
				(this.buyerSupplier === 'buyer' &&
					apsRoles.includes('JV Buyer')) ||
				(this.buyerSupplier === 'buyer' &&
					apsRoles.includes('JV Engineer')) ||
				(this.buyerSupplier === 'buyer' &&
					apsRoles.includes('JV Costestimator')) ||
				apsRoles.includes('eDCM_IT_ADMIN') ||
				apsRoles.includes('eDCM_Super_Buyer') ||
				apsRoles.includes('EDCM_VIEW_ONLY'))
		) {
			// Intentionally left empty
		} else {
			this.router.navigate(['/permission-denied']);
		}
		if (this.isPdEnggRole()) {
			this.isPdEngg = true;
		}
	}

	ngOnInit(): void {
		// this.retrieveRouteParameter();
		this.onPsaSelect(null);
		this.fetchRFIList();

		this.breadCrumItems = [
			{
				label: 'Buyer Home Page',
				url: '#/buyer-supplier/' + 'Buyer',
				target: '_self'
			},
			{label: 'Manual RFI', target: '_self'},
			{label: 'Create Manual RFI', target: '_self'}
		];
		this.breadCrumItems = [
			{
				label: 'Buyer Home Page',
				url: '#/buyer-supplier/' + 'Buyer',
				target: '_self'
			},
			{label: 'Manual RFI', target: '_self'},
			{label: 'Create Manual RFI', target: '_self'}
		];
	}

	toggleInteranlCostDetailsEdit(value) {
		this.interanlCostDetailsEdit = value;
	}

	addNewPart() {
		let counter = this.rfiRequest.partMasterList.length;
		if (this.rfiRequest.partMasterList) {
			counter =
				this.rfiRequest.partMasterList[
					this.rfiRequest.partMasterList.length - 1
				].counter + 1;
		}
		this.rfiRequest.partMasterList.push({
			partPrefix: '',
			partBase: '',
			partSuffix: '',
			counter
		});
		console.log(this.rfiRequest.partMasterList);
	}

	deletePart(part) {
		const index = this.rfiRequest.partMasterList.indexOf(part);
		if (index !== -1) {
			this.rfiRequest.partMasterList.splice(index, 1);
		}
	}

	isAdminRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			(apsRoles && apsRoles.includes('eDCM_IT_ADMIN')) ||
			apsRoles.includes('PD_Engineer') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			apsRoles.includes('Buyer')
		);
	}

	isAdmin() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles &&
			(apsRoles.includes('eDCM_IT_ADMIN') ||
				apsRoles.includes('eDCM_Super_Buyer'))
		);
	}

	isPdEnggRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles &&
			(apsRoles.includes('PD_Engineer') ||
				apsRoles.includes('JV Engineer'))
		);
	}

	isCostEstimatorRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles &&
			(apsRoles.includes('CostEstimator') ||
				apsRoles.includes('JV Costestimator'))
		);
	}

	isEDCMViewOnlyRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('EDCM_VIEW_ONLY');
	}

	fetchRFIList() {
		let userId = sessionStorage.getItem('userId');
		if (this.isAdminRole()) {
			userId = 'eDCM_IT_ADMIN';
		}

		if (this.manualRFIType === 'true') {
			this.psaList = [];
			this.rfiList = [];
			this.rfiStr = null;
			this.psa = null;

			this.rfiThreasholdService
				.getPSAList(userId)
				.subscribe((response) => {
					this.psaList = response;
				});
		} else {
			this.psaList = [];
			this.rfiList = [];
			this.rfiStr = null;
			this.psa = null;

			this.rfiRequestService
				.findRfiListForUser(userId)
				.subscribe((response) => {
					this.rfiList = response;
				});
		}
	}

	onTimerSelect(newValue) {
		console.log('onTimerSelect' + newValue.value);
		this.timerOption = newValue.value;
	}

	getRFIHistoryList(rfiPrefix, rfiId) {
		this.historyService.findAll(rfiPrefix, rfiId).subscribe((response) => {
			this.rfiHistoryList = response;
		});
	}

	onPsaSelect(event) {
		if (this.psa1 && this.psa1.value) {
			this.psa = this.psa1.value;
			this.rfiRequest.psa = this.psa;
			this.rfiRequestService
				.findRfiListForPsa(this.psa)
				.subscribe((response) => {
					this.rfiList = response;
				});
		} else {
			this.rfiRequest = {};
			this.rfiRequest.rfiId = this.rfiOrgRequest.rfiId;
			this.rfiRequest.rfiCreatedDate = this.rfiOrgRequest.rfiCreatedDate;
			this.rfiRequest.statusId = 12;
			this.rfiStr = null;
		}
		if (event && event.value == null) {
			location.reload();
		}
	}

	populateDataByPsa() {
		if (
			this.rfiRequest.psa != null &&
			this.rfiRequest.psa.trim().length > 0
		) {
			this.rfiRequestService
				.findRfiForPsa(this.rfiRequest.psa)
				.subscribe((response) => {
					if (
						response &&
						response.constructor === Object &&
						Object.keys(response).length !== 0
					) {
						this.rfiRequest.buyerCode =
							response.buyerCode != null
								? response.buyerCode.trim()
								: '';
						this.rfiRequest.purCommCode =
							response.commodity != null
								? response.commodity.trim()
								: '';
						this.rfiRequest.supplier = response.supplierContact;
						this.rfiRequest.secondSupplier = response.suppEnggEmail;
						this.rfiRequest.siteCode =
							response.supplier != null
								? response.supplier.trim()
								: '';
						this.rfiRequest.costEstimator =
							response.CostEstimator != null
								? response.CostEstimator.trim()
								: '';
						this.rfiRequest.resCur = response.respCur;
						this.rfiRequest.leadPrg =
							response.leadProgram != null
								? response.leadProgram.trim()
								: '';
						this.rfiRequest.plantCode =
							response.plantCode != null
								? response.plantCode.trim()
								: '';
						this.rfiRequest.partNumber =
							response.partNumber != null
								? response.partNumber.trim()
								: '';
						this.rfiRequest.purGroup = response.purchasingGroup;
					}
				});
		}
	}

	onRFISelect() {
		if (this.rfiStr && this.rfiStr.value) {
			this.rfiRequestService
				.findById(this.rfiStr.value)
				.subscribe((response) => {
					this.rfiRequest = response;
					this.retriveAllCostsForSelecedRFI(
						response.rfiNum,
						response.prevRfiNum
					);
					this.rfiRequest.prevRfiId = this.rfiRequest.rfiId;
					this.rfiRequest.rfiId = this.rfiOrgRequest.rfiId;
					this.rfiRequest.statusId = this.rfiOrgRequest.statusId;
					this.rfiRequest.supplierStatus =
						this.rfiOrgRequest.supplierStatus;
					this.rfiRequest.buyerStatus =
						this.rfiOrgRequest.buyerStatus;
					this.rfiRequest.rfiCreatedDate =
						this.rfiOrgRequest.rfiCreatedDate;
					this.rfiRequest.rfiNum = this.rfiOrgRequest.rfiNum;
					this.rfiRequest.privateRFI = false;
					this.rfiRequest.rfiPrefix = this.rfiOrgRequest.rfiPrefix;
					if (this.rfiRequest.costEstimator != null) {
						this.rfiRequest.costEstimator =
							this.rfiRequest.costEstimator.trim();
					}
					this.rfiRequest.statusId = 12;
					this.validationCheck();
					this.validationCheckEngineer(false);
					if (
						this.rfiRequest.toolType === 'true' ||
						this.rfiRequest.toolType === true
					) {
						this.isToolingWholeOption = 'false';
					} else {
						this.isToolingWholeOption = 'true';
					}
					this.initializeRFICosts(this.rfiRequest);
					this.setDefaultCDSId();

					if (!this.rfiRequest.partMasterList) {
						this.rfiRequest.partMasterList = [];
						this.rfiRequest.partMasterList.push({
							partPrefix: '',
							partBase: '',
							partSuffix: '',
							partName: '',
							counter: 0
						});
					}
				});
		} else {
			this.rfiRequest = {};
			this.rfiRequest.rfiId = this.rfiOrgRequest.rfiId;
			this.rfiRequest.rfiCreatedDate = this.rfiOrgRequest.rfiCreatedDate;
			this.rfiRequest.statusId = 12;
			this.setDefaultCDSId();
		}
	}

	getLumpSumHeader() {
		return this.deltaLumpSumHeader;
	}

	showDialogToAdd() {
		this.newTooling = true;
		this.tooling = {};
		this.displayDialog = true;
	}

	validateToolingCost() {
		this.isProdToolingWarn = false;
		if (!this.rfiRequest.rfiCost || this.rfiRequest.rfiCost === 0) {
			this.isProdToolingWarn = true;
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Warning',
				detail: 'RFI Estimate for Production Tooling equals zero (0), all previously approved Production Tooling will be removed.'
			});
		}
	}

	isShowTimeDisClaimer() {
		const isSupplier = this.buyerSupplier === 'supplier';
		return (
			isSupplier &&
			(this.rfiRequest.statusId === 1 ||
				this.rfiRequest.statusId === 2 ||
				this.rfiRequest.statusId === 5 ||
				this.rfiRequest.statusId === 6) &&
			this.timerOption.value &&
			this.timerOption.value !== '0'
		);
	}

	showDetails(details) {
		this.detailDialog = true;
		this.dialogDetails = details;
	}

	delete(data) {
		this.confirmationService.confirm({
			message: 'Are you sure you want to Delete Tooling Breakdown',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				const index = this.toolingRecords.indexOf(data);
				this.toolingRecords = this.toolingRecords.filter(
					(val, i) => i !== index
				);
				this.setTotalTooling(this.toolingRecords);

				if (this.toolingRecords.length === 0) {
					this.isToolingWholeOption = 'true';
				}
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'success',
						summary: 'Tooling Breakdown Item Deleted'
					});
				}, 30);
			},
			reject: () => {
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'warn',
						summary:
							'Delete Operation Not Able to Perform Please Check.'
					});
				}, 30);
			}
		});
	}

	onToolingClose() {
		this.displayDialog = false;
	}

	save(data) {
		if (!data.cost || data.cost === undefined) {
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Warning',
				detail: 'Tooling cost must be filled.'
			});
			return;
		}
		const toolings = [...this.toolingRecords];
		if (this.newTooling) {
			toolings.push(data);
		} else {
			toolings[this.toolingRecords.indexOf(this.selectedTooling)] = data;
		}

		if (this.setTotalTooling(toolings)) {
			this.toolingRecords = toolings;
			this.tooling = null;
			this.displayDialog = false;
		}
	}

	onRowSelect(data) {
		this.newTooling = false;
		this.tooling = this.cloneTooling(data);
		this.selectedTooling = data;
		this.displayDialog = true;
		this.setTotalTooling(this.tooling);
	}

	cloneTooling(tooling) {
		const newTooling = {};
		// tslint:disable-next-line:forin
		// eslint-disable-next-line guard-for-in
		for (const prop in tooling) {
			newTooling[prop] = tooling[prop];
		}
		return newTooling;
	}

	isLumpSumpEdit() {
		const data = this.commercialCosts.filter(
			(rowData) => rowData.costBucket === 'ED&T paid in Piece Price'
		)[0];
		return !(
			data &&
			(data.lsIncludeFIn === undefined || data.lsIncludeFIn === null)
		);
	}

	isLSINIncludeFINEdit() {
		const data = this.edtCosts[0];
		console.log(data);
		return !(data && (data.finance === undefined || data.finance === null));
	}

	isEditable() {
		return (
			this.selectedMode === 'Edit' ||
			window.location.hash.includes('/create/')
		);
	}

	isResponseCurEditable(value) {
		if (value === 'resCur') {
			return (
				(this.selectedMode === 'Edit' &&
					!this.isResCurEditable &&
					(this.rfiRequest.statusId === 12 ||
						this.rfiRequest.statusId === 2)) ||
				(this.rfiRequest.statusId !== 1 &&
					window.location.hash.includes('/create/')) ||
				(this.selectedMode === 'View' &&
					this.rfiRequest.statusId === 12)
			);
		} else {
			return (
				(this.selectedMode === 'Edit' &&
					(this.rfiRequest.statusId === 12 ||
						this.rfiRequest.statusId === 2 ||
						this.rfiRequest.statusId === 6)) ||
				(!window.location.hash.includes('/buyer/') &&
					this.rfiRequest.statusId !== 1 &&
					window.location.hash.includes('/create/')) ||
				(this.selectedMode === 'View' &&
					this.rfiRequest.statusId === 12)
			);
		}
	}

	loadToolingItems() {
		this.isToolingWholeOption = 'false';
		this.rfiRequest.toolType = 'false';

		this.retriveTooling();
	}

	resetToolingItems() {
		this.isToolingWholeOption = 'true';
		this.rfiRequest.toolType = 'true';
		this.toolingRecords = [];
	}

	stringNotEmptyOrNull(str) {
		return str && str.trim() !== '' && str.trim() !== 'null';
	}

	fetchRFIHeaderData() {
		this.manualRfiRequestService
			.findById(this.rfiId)
			.subscribe((response) => {
				this.rfiRequest = response;
				this.rfiRequest.internalComment = response.internalComment;
				this.rfiOrgRequest = response;
				if (this.buyerSupplier === 'buyer') {
					this.validationCheck();
				}
				if (this.stringNotEmptyOrNull(this.rfiRequest.resCur)) {
					this.prevToolingRFILable +=
						'(' + this.rfiRequest.resCur + ')';
					this.prevRFILable += '(' + this.rfiRequest.resCur + ')';
					this.prevLumpSumRFILable +=
						'(' + this.rfiRequest.resCur + ')';
				}
				this.supplierRFILumpSum += this.stringNotEmptyOrNull(
					this.rfiRequest.resCur
				)
					? '(' + this.rfiRequest.resCur + ')'
					: '';
				this.curretRFIHeader += this.stringNotEmptyOrNull(
					this.rfiRequest.resCur
				)
					? '(' + this.rfiRequest.resCur + ')'
					: '';
				this.toolingRFIHeader += this.stringNotEmptyOrNull(
					this.rfiRequest.resCur
				)
					? '(' + this.rfiRequest.resCur + ')'
					: '';

				this.isBeginRFI(this.rfiRequest);
				this.isSubmittedToPD(this.rfiRequest);
				this.isReSubmitRFI(this.rfiRequest);

				this.rfiRequest.toolType = this.rfiRequest.toolType
					? 'true'
					: 'false';

				if (this.rfiRequest.timer) {
					this.timerOption = this.timerList.filter(
						(time) => time.value === this.rfiRequest.timer
					)[0];
				}

				if (
					this.isShowReqAddTime() &&
					this.buyerSupplier.toLowerCase() !== 'buyer'
				) {
					this.calculateTimerTime(this.rfiRequest);
				}

				if (this.isEditRFI() && !this.negativeTimer) {
					this.updateModType();
				}

				this.retriveAllCosts(
					this.rfiRequest.rfiNum,
					this.rfiRequest.prevRfiNum
				);
				this.retriveTooling();
				this.getRFIHistoryList(
					this.rfiRequest.rfiPrefix,
					this.rfiRequest.rfiNum
				);

				setInterval(() => {
					if (
						this.isShowReqAddTime() &&
						this.buyerSupplier.toLowerCase() !== 'buyer'
					) {
						this.calculateTimerTime(this.rfiRequest);
					}
				}, 1000);

				this.rfiRequest.partCostTarget = this.get4DigitFixed(
					this.rfiRequest.partCostTarget
				);
				this.rfiRequest.pdMatCostEstimate = this.get4DigitFixed(
					this.rfiRequest.pdMatCostEstimate
				);
				this.rfiRequest.e3pMatCostEstimate = this.get4DigitFixed(
					this.rfiRequest.e3pMatCostEstimate
				);
				this.rfiRequest.toolingCostTarget = this.get4DigitFixed(
					this.rfiRequest.toolingCostTarget
				);
				this.rfiRequest.pdToolingEstimate = this.get4DigitFixed(
					this.rfiRequest.pdToolingEstimate
				);
				this.rfiRequest.e3pToolingEstimate = this.get4DigitFixed(
					this.rfiRequest.e3pToolingEstimate
				);
				this.rfiRequest.rfiPrevCost = this.get4DigitFixedPrice(
					this.rfiRequest.rfiPrevCost
				);
				//this.rfiRequest.rfiCost = this.get4Digit(
				//	this.rfiRequest.rfiCost
				//);
				if (this.rfiRequest.costEstimator != null) {
					this.rfiRequest.costEstimator =
						this.rfiRequest.costEstimator.trim();
				}

				const buyerSupplier =
					this.buyerSupplier.toLowerCase() === 'buyer'
						? 'Buyer Home Page'
						: 'Supplier Home Page';
				const buyerSupplierUrl =
					this.buyerSupplier.toLowerCase() === 'buyer'
						? 'buyer'
						: 'supplier';
				let statusString =
					this.status === 'U'
						? 'Unread RFIS'
						: this.status === 'I'
							? 'InProgress RFIs'
							: 'Completed RFIs';
				if (this.status && this.status.includes('SelectRFI')) {
					statusString = 'Manual RFIs';
				}
				const program = this.program;

				// if (program && program !== 'null') {
				// tslint:disable-next-line:triple-equals
				// 	statusString = this.status === 'U' ? 'PIE Unread RFIS' : (this.status === 'I' ? 'PIE InProgress RFIs' : 'PIE Completed RFIs');
				// }

				let statusUrl =
					'#/manual-rfi-request/' +
					this.buyerSupplier +
					'/program/' +
					program +
					'/' +
					this.status;
				if (this.status && this.status.includes('SelectRFI')) {
					const supp =
						this.buyerSupplier === 'buyer' ? 'Buyer' : 'Supplier';
					statusUrl =
						'#/manual-rfi-request/' +
						this.buyerSupplier +
						'/all/all/' +
						supp +
						'/false/false';
				}
				console.log(statusUrl);

				this.breadCrumItems = [
					{
						label: buyerSupplier,
						url: '#/buyer-supplier/' + buyerSupplierUrl,
						target: '_self'
					},
					{
						label: statusString,
						url: this.getBackNavUrl(),
						target: '_self'
					},
					{label: this.rfiRequest.rfiId}
				];

				// if (program && program !== 'null' && !this.status.includes('SelectRFI')) {
				// 	if (statusString === 'PIE Completed RFIs') {
				// 		this.breadCrumItems.splice(2, 0, {label: this.status, url: statusUrl});
				// 		this.breadCrumItems.splice(3, 0, {label: program, url: statusUrl});
				// 	} else {
				// 		this.breadCrumItems.splice(2, 0, {label: program, url: statusUrl});
				// 	}
				// }
				this.isResCurEditable = this.rfiRequest.resCur;
				this.isSiteCodeEditable = this.rfiRequest.siteCode;
			});
	}

	get4DigitFixedPrice(cost: any): string {
		if (typeof cost === 'number') {
			return cost.toFixed(4);
		}
		return '0.0000';
	}

	updateModType() {
		this.modeType = [
			/*{title: 'View', label: 'View', value: 'View', icon: 'fa fa-eye'},*/
			{title: 'Edit', label: 'Edit', value: 'Edit', icon: 'fa fa-edit'}
		];
	}

	initializeFormValidation(questions) {
		const formObj = {};

		for (const ques of questions) {
			formObj[ques.lookupId + 'question'] = new FormControl(
				{disabled: true},
				Validators.required
			);
		}

		this.rfiRequestForm = this.fb.group(formObj);
	}

	loadRFIData() {
		if (this.mode === 'edit') {
			this.isLoader = true;
			this.fetchRFIHeaderData();

			console.log(this.rfiRequest);
			console.log(this.materialCosts);
		} else {
			this.manualRfiRequestService
				.createNewRFI()
				.subscribe((response) => {
					response.postfdjF = true;
					response.privateRFI = false;
					this.rfiRequest = response;
					this.rfiOrgRequest = response;
					this.initializeRFICosts(this.rfiRequest);
					this.retriveAllCosts(response.rfiNum, response.prevRfiNum);

					this.setDefaultCDSId();
				});
		}
	}

	goToBuyer() {
		this.router.navigate([
			'/manual-rfi-request/' + this.buyerSupplier + '/all/all/null'
		]);
	}

	changeView() {
		console.log(this.selectedMode);
	}

	onRowEditInit(cost, cloned) {
		cloned[cost.lookupId] = cost;
	}

	onRowEditSave() {
		this.updateTotalCost(false);
	}

	displayMessage(message) {
		setTimeout(() => {
			this.messageService.add({
				life: 300000,
				severity: 'success',
				summary: message
			});
		}, 10000);
	}

	onRowEditCancel(cost, costs, cloned, index: number) {
		costs[index] = cloned[cost.lookupId];
		delete cloned[cost.lookupId];
	}

	onSave() {
		if (this.isEditMode() || this.isEditRFI()) {
			//const materialSubTotalCost = this.materialCosts.filter(
			(cost) => cost.isTotal;
			// if (materialSubTotalCost[0] && materialSubTotalCost[0].curRFI == 0) {
			// 	that.messageService.add({
			// 		life: 300000, severity: 'warn',
			// 		summary: 'Warning',
			// 		detail: 'The RFI Estimate Subtotal cannot equal zero (0). If the values in the Cost Breakdown total zero (0), all previously approved Piece Price will be removed.  '
			// 	});

			// 	return;
			// }

			// that.confirmationService.confirm({
			// 	message: 'Are you sure you want to "Save" data',
			// 	header: 'Confirmation',
			// 	icon: 'pi pi-exclamation-triangle',
			// 	accept: () => {
			this.saveRFI(this.rfiRequest.statusId, false);
			//that.updateRFI();
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'success',
					summary: 'RFI saved successfully'
				});
			}, 2000);
			this.selectedMode = 'Edit';
			// }
			// });
		}
		this.selectedMode = 'Edit';
	}

	getTotalTooling() {
		this.toolingTotal = 0;
		this.toolingRecords.forEach((tool) => {
			this.toolingTotal += Number(tool.cost);
		});
	}

	setTotalTooling(toolings) {
		let toolingTotal = 0;
		toolings.forEach((tool) => {
			toolingTotal += Number(tool.cost);
		});

		const rfiCost = this.getRFIAdd(
			this.rfiRequest.rfiPrevCost,
			toolingTotal
		);
		if (Math.sign(Number(rfiCost)) === -1) {
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Warning',
				detail: 'The Production Tooling RFI Estimate cannot be negative. Please check the entered value(s).'
			});
			return false;
		} else {
			this.toolingTotal = toolingTotal;
		}

		return true;
	}

	getBackNavUrl() {
		const program = this.program;
		let back = 'true'; //this.searchRfi === 'false' ? false : true;
		if (this.searchRfi === 'false' && this.buyerSupplier === 'buyer') {
			back = 'true';
		}
		sessionStorage.setItem('back', back);

		let statusUrl =
			'/#/manual-rfi-request/' +
			this.buyerSupplier +
			'/programs/' +
			program +
			'/' +
			this.status +
			'/' +
			back +
			'/' +
			this.searchRfi;
		if (this.status && this.status.includes('SelectRFI')) {
			const supp = this.buyerSupplier === 'buyer' ? 'Buyer' : 'Supplier';
			statusUrl =
				'/#/manual-rfi-request/' +
				this.buyerSupplier +
				'/all/all/' +
				supp +
				'SelectRFI' +
				'/' +
				back +
				'/' +
				this.searchRfi;
		}

		return statusUrl;
	}

	getStatusUrl() {
		const program = this.program;
		let back = 'true'; //this.searchRfi === 'false' ? false : true;
		if (this.searchRfi === 'false' && this.buyerSupplier === 'buyer') {
			back = 'true';
		}
		sessionStorage.setItem('back', back);

		let statusUrl =
			'/manual-rfi-request/' +
			this.buyerSupplier +
			'/programs/' +
			program +
			'/' +
			this.status +
			'/' +
			back +
			'/' +
			this.searchRfi;
		if (this.status && this.status.includes('SelectRFI')) {
			const supp = this.buyerSupplier === 'buyer' ? 'Buyer' : 'Supplier';
			statusUrl =
				'/manual-rfi-request/' +
				this.buyerSupplier +
				'/all/all/' +
				supp +
				'SelectRFI' +
				'/' +
				back +
				'/' +
				this.searchRfi;
		}

		this.router.navigate([statusUrl]);
	}

	onCancel() {
		this.confirmationService.confirm({
			message:
				'You have Unsaved work and closing will lose your data, are you sure you want to "Close" before saving ',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.manualRfiRequestService
					.deleteRFIInternalCostDetails(this.rfiRequest)
					.subscribe(() => {
						console.log('deleteRFIInternalCostDetails');
					});
				this.getStatusUrl();
			},
			reject: () => {
				//do nothing
			}
		});
	}

	/*	onReset() {
			this.loadRFIData();
		}*/

	isPartNameValid() {
		let valid = true;
		for (const part of this.rfiRequest.partMasterList) {
			if (!part.partName) {
				valid = false;
			}
		}

		return valid;
	}

	isMandatoryFields() {
		const mandatory =
			this.rfiRequest.buyerCode &&
			this.rfiRequest.supplier &&
			this.rfiRequest.changeDesc &&
			this.isPartNameValid();
		this.isSubmit = !mandatory;

		return mandatory;
	}

	onSubmit() {
		// if (!this.isMandatoryFields()) {
		// 	this.messageService.add({life: 300000, severity: 'warn', summary: 'Warning', detail: 'Please make sure to enter mandatory RFI fields before submitting.'});
		// 	return;
		// }

		if (this.rfiRequest.rfiCost === '' && this.toolingTotal === 0) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'Supplier New Tooling field must contain a value (may be zero).'
			});
			return;
		}
		if (this.isValidBuyer || this.isValidDnR || this.isValidCostEstimator) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Waning',
					detail: 'Fix the error before submitting.'
				});
			}, 30);
		} else {
			this.confirmationService.confirm({
				message: 'Are you sure you want to "Submit RFI" data',
				header: 'Confirmation',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					const isTollingFilled = this.isAllFieldsFilled(
						this.rfiQuestions,
						'Toolings'
					);
					console.log(isTollingFilled);
					const materialSubTotalCost = this.materialCosts.filter(
						(cost) => cost.isTotal
					);
					if (
						materialSubTotalCost[0] &&
						materialSubTotalCost[0].curRFI === 0
					) {
						this.messageService.add({
							life: 300000,
							severity: 'warn',
							summary:
								'The RFI Estimate Subtotal cannot equal zero (0). If the values in the Cost Breakdown total zero (0), all previously approved Piece Price will be removed.  '
						});

						return;
					}

					this.rfiRequest.statusId = 15;
					this.saveRFI(this.rfiRequest.statusId, true);
					this.isResCurEditable = null;
					this.isSiteCodeEditable = null;

					this.messageService.add({
						life: 300000,
						severity: 'success',
						summary: 'RFI submitted for Review'
					});
					this.selectedMode = 'View';

					// let manadatoryWarnMesg = '';
					// if (!isTollingFilled) {
					// 	manadatoryWarnMesg += 'Please add at least one Tooling Item before submitting RFI, even if value is the same as Previous RFI Production Tooling';
					// 	this.messageService.add({life: 300000, severity: 'warn', summary: 'Warning', detail: manadatoryWarnMesg});
					// }
					// this.updateStatus(
					// 	15,
					// 	'RESPONSE SUBMITTED',
					// 	false
					// );
				},
				reject: () => {
					this.messageService.add({
						life: 300000,
						severity: 'warn',
						summary: 'Warning',
						detail: 'RFI has not been submitted.'
					});
					//	that.retriveAllCosts(that.rfiRequest.rfiNum);
				}
			});
		}
	}

	updateStatus(statusId, message, isUpdateRfi) {
		this.manualRfiRequestService
			.updateStatusById(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum,
				statusId
			)
			.subscribe((response) => {
				//	this.messageService.add({life: 300000, severity: 'success', summary: 'Success', detail: message});
				this.rfiRequest.statusId = statusId;

				this.isBeginRFI(this.rfiRequest);
				this.isSubmittedToPD(this.rfiRequest);
				this.isReSubmitRFI(this.rfiRequest);

				if (isUpdateRfi) {
					this.updateRFI();
				}

				if (this.isEditRFI() && !this.negativeTimer) {
					this.updateModType();
				} else {
					this.modeType = [
						/*{title: 'View', label: 'View', value: 'View', icon: 'fa fa-eye'}*/
					];
				}

				response.buyerComments = this.rfiRequest.buyerComments;
				response.rfiComments = this.rfiRequest.rfiComments;
				response.pdComments = this.rfiRequest.pdComments;
				response.internalComment = this.rfiRequest.internalComment;
				this.rfiRequest = response;
				this.rfiRequest.toolType = this.isToolType() ? 'true' : 'false';
			});
	}

	reSubmitRFIClick() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to "Resubmit RFI"',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.updateStatus(5, 'RFI resubmitted back to Supplier', true);
			},
			reject: () => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Warning',
					detail: 'RFI has not been re-submitted.'
				});
			}
		});
	}

	submitToPDClick() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to "Submit to PD"',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				//const program = this.rfiRequest.leadPrg;
				//const commodity = this.rfiRequest.purCommCode;
				const psa = this.rfiRequest.psa;
				this.updateRFI();
				this.updateStatus(15, 'RESPONSE SUBMITTED', false);

				const userId = 'eDCM_IT_ADMIN';

				this.rfiThreasholdService
					.findThresholdForPsa(psa, userId)
					.subscribe((threshold) => {
						let commodityNonZero = false;
						if (threshold.mcost > 0 || threshold.tcost > 0) {
							commodityNonZero = true;
						}
						console.log(commodityNonZero);
						this.updateStatus(3, 'RFI submitted to PD', true);
						// if (!commodityNonZero) {
						// 	that.updateStatus(3, 'RFI submitted to PD', true);
						// } else {
						// 	that.updateStatus(3, 'RFI submitted to PD', true);
						// }
					});
			},
			reject: () => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Warning',
					detail: 'RFI has not been Submit to PD.'
				});
			}
		});
	}

	updateRFI() {
		const isSupplier = this.buyerSupplier === 'supplier';
		const comment = isSupplier
			? this.rfiRequest.rfiComments
			: this.rfiRequest.buyerComments;

		//let rfiCost = this.getRFIAdd(this.rfiRequest.rfiPrevCost, this.toolingTotal);
		//if (this.isToolType()) {
		const rfiCost = Number(this.getToolingCost());
		const internalComment = this.rfiRequest.internalComment;
		//}

		const rfi = {
			comment,
			rfiCost,
			internalComment,
			pdComments: this.rfiRequest.pdComments
		};

		this.manualRfiRequestService
			.updateRFI(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum,
				rfi,
				isSupplier
			)
			.subscribe((response) => {
				console.log(response);
				// this.updateStatus(
				// 	15,
				// 	'RESPONSE SUBMITTED',
				// 	false
				// );
			});
	}

	isAllFieldsFilled(questions, type) {
		if ('Materials' === type) {
			for (const cost of this.materialCosts) {
				if (!cost.isTotal && cost.curRFI === 0) {
					return false;
				}
			}
		}

		if ('Commercials' === type) {
			for (const cost of this.commercialCosts) {
				if (!cost.isTotal && cost.curRFI === 0) {
					return false;
				}
			}
		}

		if ('LumpSum' === type) {
			for (const cost of this.commercialCosts) {
				if (cost.costBucket === 'ED&T paid in Piece Price') {
					if (!cost.lsIncludeFIn && !cost.lumpSum) {
						return false;
					}
				}
			}
		}
		if ('Toolings' === type) {
			if (this.toolingRecords.length === 0) {
				return false;
			}
			if (this.toolingRecords.length !== 0) {
				for (const toolingRecord of this.toolingRecords) {
					if (
						toolingRecord.cost === undefined ||
						toolingRecord.cost == null
					) {
						return false;
					}
				}
			}
		}
		return true;
	}

	updateRFIStatus() {
		if (this.rfiRequest.statusId === 5) {
			this.updateStatus(
				6,
				'RFI request updated response required',
				false
			);
		} else {
			this.updateStatus(
				2,
				'RFI request is in Acknowledgement status now',
				false
			);
		}
	}

	isBeginRFI(rfiRequest) {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		let editAllowed = true;
		if (
			(apsRoles && apsRoles.includes('eDCM_Super_Buyer')) ||
			this.isPdEngg
		) {
			editAllowed = false;
		}

		this.beginRFI =
			editAllowed &&
			this.buyerSupplier === 'supplier' &&
			(rfiRequest.statusId === 1 || rfiRequest.statusId === 5);
		this.showCalculation =
			(this.buyerSupplier === 'supplier' && rfiRequest.statusId === 1) ||
			//|| (this.buyerSupplier === 'buyer' && (rfiRequest.statusId !== 3 && rfiRequest.statusId !== 7 && rfiRequest.statusId !== 8 && rfiRequest.statusId !== 9 && rfiRequest.statusId !== 10 && rfiRequest.statusId !== 5));
			(this.buyerSupplier === 'buyer' &&
				this.isBuyerOwnRfi() &&
				rfiRequest.statusId === 3 &&
				rfiRequest.statusId === 7 &&
				rfiRequest.statusId === 8 &&
				rfiRequest.statusId === 9 &&
				rfiRequest.statusId === 10 &&
				rfiRequest.statusId === 5);
	}

	isEditRFI() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		let editAllowed = true;
		if (
			(apsRoles && apsRoles.includes('eDCM_Super_Buyer')) ||
			this.isPdEnggRole() ||
			this.isCostEstimatorRole()
		) {
			editAllowed = false;
		}

		return (
			editAllowed &&
			(this.rfiRequest.statusId === 2 ||
				this.rfiRequest.statusId === 6) &&
			this.buyerSupplier === 'supplier'
		);
	}

	isShowReqAddTime() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		let editAllowed = true;
		if (
			(apsRoles && apsRoles.includes('eDCM_Super_Buyer')) ||
			this.isPdEnggRole() ||
			this.isCostEstimatorRole()
		) {
			editAllowed = false;
		}

		return (
			editAllowed &&
			((this.isSupplier() &&
				(this.rfiRequest.statusId === 1 ||
					this.rfiRequest.statusId === 2 ||
					this.rfiRequest.statusId === 5 ||
					this.rfiRequest.statusId === 6)) ||
				(this.buyerSupplier.toLowerCase() === 'buyer' &&
					this.rfiRequest.statusId === 15)) &&
			this.timerOption.value &&
			this.timerOption.value !== '0'
		);
	}

	isReviewSupplierResponse() {
		return (
			this.buyerSupplier.toLowerCase() === 'buyer' &&
			this.rfiRequest.statusId === 15 &&
			(this.isBuyerOwnRfi() ||
				this.isVaccationRfi() ||
				this.isAdminRole())
		);
	}

	isSubmittedToPD(rfiRequest) {
		this.submitToPDRFI =
			rfiRequest.statusId === 7 &&
			(this.isBuyerOwnRfi() ||
				this.isVaccationRfi() ||
				this.isAdminRole());
	}

	isReSubmitRFI(rfiRequest) {
		this.reSubmitRFI =
			(rfiRequest.statusId === 7 || rfiRequest.statusId === 4) &&
			(this.isBuyerOwnRfi() ||
				this.isVaccationRfi() ||
				this.isAdminRole());
	}

	isBuyerComment() {
		return (
			this.buyerSupplier.toLowerCase() === 'buyer' &&
			(this.rfiRequest.statusId === 4 ||
				this.rfiRequest.statusId === 7 ||
				this.rfiRequest.statusId === 12 ||
				this.rfiRequest.statusId === 15) &&
			(this.isBuyerOwnRfi() || this.isPdEngg || this.isVaccationRfi())
		);
	}

	isInternalComment() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		console.log(apsRoles);
		return this.buyerSupplier.toLowerCase() !== 'supplier';
	}

	isBuyer() {
		const isBuyer = this.isBuyerRole();
		return this.buyerSupplier.toLowerCase() === 'buyer' && isBuyer;
	}

	isSupplier() {
		return this.buyerSupplier.toLowerCase() === 'supplier';
	}

	isBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer');
	}

	isPdEnggOrReadOnly() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles && apsRoles.includes('EDCM_VIEW_ONLY');
	}

	isBuyerOwnRfi() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const buyer =
			this.buyerSupplier.toLowerCase() === 'buyer' &&
			!this.isPdEnggOrReadOnly();
		const isITAdmin =
			apsRoles &&
			(apsRoles.includes('eDCM_IT_ADMIN') ||
				apsRoles.includes('eDCM_Super_Buyer'));
		return buyer || isITAdmin;
	}

	isResponseCurAvail() {
		const resonseCur = this.rfiRequest.resCur
			? this.rfiRequest.resCur.trim()
			: this.rfiRequest.resCur;
		return !this.isSupplier() || (this.isSupplier() && !!resonseCur);
	}

	isOwnRfi() {
		if (this.new === 'true') {
			return true;
		}

		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const buyer =
			this.buyerSupplier.toLowerCase() === 'buyer' &&
			!this.isPdEnggOrReadOnly();
		const isITAdmin =
			apsRoles &&
			(apsRoles.includes('eDCM_IT_ADMIN') ||
				apsRoles.includes('eDCM_Super_Buyer'));
		const jvBuyerEnginer =
			apsRoles &&
			(apsRoles.includes('JV Buyer') ||
				apsRoles.includes('JV Engineer') ||
				apsRoles.includes('Buyer') ||
				apsRoles.includes('PD_Engineer'));
		console.log(jvBuyerEnginer);
		const userId = sessionStorage.getItem('userId').trim().toLowerCase();
		let userCode = this.rfiRequest.buyerCode;
		if (this.isPdEnggRole()) {
			userCode = this.rfiRequest.drCDSID;
		}

		return (
			buyer &&
			!this.isCostEstimatorRole() &&
			(isITAdmin ||
				(userCode != null && userCode.trim().toLowerCase() === userId))
		);
	}

	isVaccationRfi() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const buyer =
			this.buyerSupplier.toLowerCase() === 'buyer' && !this.isPdEngg;
		const userId = sessionStorage.getItem('userId');

		const isITAdmin =
			(apsRoles && apsRoles.includes('eDCM_IT_ADMIN')) ||
			apsRoles.includes('eDCM_Super_Buyer');
		return (
			buyer ||
			isITAdmin ||
			(this.rfiRequest.vaccationCode != null &&
				this.rfiRequest.vaccationCode.trim().toLowerCase() ===
					userId.trim().toLowerCase())
		);
	}

	getStatusString() {
		switch (this.rfiRequest.statusId) {
			case 8:
				if (this.buyerSupplier === 'supplier') {
					this.statusColor = '#000000';
				} else {
					this.statusColor = '#00c200';
				}
				break;
			case 9:
				if (this.buyerSupplier === 'supplier') {
					this.statusColor = '#000000';
				} else {
					this.statusColor = '#c20000';
				}
				break;
			case 10:
				if (this.buyerSupplier === 'supplier') {
					this.statusColor = '#000000';
				} else {
					this.statusColor = '#c20000';
				}
				break;
			case 3:
			case 4:
			case 7:
				this.statusColor = '#000000';
				break;
			case 2:
				this.statusColor = '#000000';
				break;
			case 1:
				this.statusColor = '#000000';
				break;
		}

		return this.buyerSupplier === 'supplier'
			? this.rfiRequest.supplierStatus
			: this.rfiRequest.buyerStatus;
	}

	getRFIDiff(prevRFI, curRFI) {
		const diff = curRFI - prevRFI;
		const amount = parseFloat(Math.abs(diff).toFixed(5)).toLocaleString(
			'en-US',
			{
				minimumFractionDigits: 5,
				maximumFractionDigits: 5
			}
		);

		return (
			(Math.sign(diff) === 1 ? '-' : Math.sign(diff) === -1 ? '+' : '') +
			amount
		);
	}

	getRFIDiffSign(prevRFI, curRFI) {
		const diff = curRFI - prevRFI;
		return Math.sign(diff) === 1 ? -1 : Math.sign(diff) === -1 ? 1 : 0;
	}

	getRFIlumpDiff(curRFI, prevRFI) {
		const diff = curRFI - prevRFI;
		//const result = (Math.sign(diff) === 1 ? '-' : (Math.sign(diff) === -1 ? '+' : '')) + Math.abs(diff).toFixed(0);
		const amount = parseFloat(Math.abs(diff).toFixed(0)).toLocaleString(
			'en-US'
		);
		return (
			(Math.sign(diff) === -1 ? '-' : Math.sign(diff) === 1 ? '+' : '') +
			amount
		);
	}

	getRFIDelta() {
		let diff = 0;
		if (this.isToolingWholeOption === 'true') {
			diff = this.rfiRequest.rfiCost - this.rfiRequest.rfiPrevCost;
		} else {
			diff = this.toolingTotal - this.rfiRequest.rfiPrevCost;
		}

		const amount = parseFloat(Math.abs(diff).toFixed(0)).toLocaleString(
			'en-US'
		);

		return (
			(Math.sign(diff) === -1 ? '-' : Math.sign(diff) === 1 ? '+' : '') +
			amount
		);
	}

	getRFIDeltaSign() {
		let diff = 0;
		if (this.isToolingWholeOption === 'true') {
			diff = this.rfiRequest.rfiCost - this.rfiRequest.rfiPrevCost;
		} else {
			diff = this.toolingTotal - this.rfiRequest.rfiPrevCost;
		}

		return Math.sign(diff) === 1 ? 1 : Math.sign(diff) === -1 ? -1 : 0;
	}

	getRFIAdd(prevRFI, totaTooling) {
		const add: number = Number(totaTooling) + Number(prevRFI);
		const result = add.toFixed(0);
		const amount = parseFloat(result).toLocaleString('en-US', {
			minimumFractionDigits: 5,
			maximumFractionDigits: 5
		});
		return amount;
	}

	get4DigitFixed(cost) {
		// Convert string input to number if necessary
		const numericCost = typeof cost === 'string' ? parseFloat(cost) : cost;

		// Check if the conversion was successful or if it's a valid number
		if (cost === null || isNaN(numericCost)) {
			return '0.0000';
		}

		// Format the number to 5 decimal places and return it
		let amount = numericCost.toFixed(5);
		amount = parseFloat(amount).toLocaleString('en-US', {
			minimumFractionDigits: 5,
			maximumFractionDigits: 5
		});

		return amount;
	}

	get4Decimal(prevRFI) {
		const amount = (Math.sign(prevRFI) * Math.abs(prevRFI)).toFixed(5);
		return parseFloat(amount).toLocaleString('en-US', {
			minimumFractionDigits: 5,
			maximumFractionDigits: 5
		});
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	decimalFilter(event): boolean {
		const reg = /^-?\d*(\.\d{0,5})?$/;
		const value = event.target.value + String.fromCharCode(event.charCode);
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode === 46) {
			return reg.test(value);
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	isPostFDJQuestionEnabled() {
		return this.rfiRequest.postfdjF;
	}

	onSelectChange(event, rowData) {
		console.log(event);
		if (event === 'No') {
			rowData.answer1 = '';
		}
	}

	updateDate(rowData, ans) {
		if (ans === 'answer1') {
			rowData.answer1 = moment(rowData.answer1).format('MM/DD/YYYY');
		} else {
			rowData.answer = moment(rowData.answer).format('MM/DD/YYYY');
		}
	}

	unSelectValue(rowData, type) {
		if (type === 'lsIncludeFIn') {
			rowData.lsIncludeFIn = null;
			/*rowData.curRFI = 0.0000;*/
		} else {
			rowData.finance = null;
			/*rowData.lumpSumA = 0.0000;*/
		}

		this.isUnselectClick = true;
	}

	isCostChange(lumpSumCost, prevRfiLumpSumA: number) {
		return (
			this.prevSubMatTotPrevRFI !== this.subMatTotPrevRFI ||
			this.prevSubComTotPrevRFI !== this.subComTotPrevRFI ||
			this.prevSubMatTotCurRFI !== this.subMatTotCurRFI ||
			this.prevSubComTotCurRFI !== this.subComTotCurRFI ||
			prevRfiLumpSumA !== lumpSumCost
		);
	}

	saveCosts() {
		let lumpSumCost = 0;
		let prevRfiLumpSumA = 0;
		for (const result of this.edtCosts) {
			lumpSumCost = result.cost;
			prevRfiLumpSumA = result.prevCost;
		}
		console.log(prevRfiLumpSumA);
		console.log(lumpSumCost);
		this.commercialCosts[1].lsIncludeFIn = this.edtCosts[0].finance;

		const costs = this.materialCosts.concat(this.commercialCosts);
		//if (this.isCostChange(lumpSumCost,prevRfiLumpSumA)) {
		this.costChange = true;
		costs.forEach((cost) => {
			cost.curRFI = parseFloat(cost.curRFI);
			cost.prevRFI = parseFloat(cost.prevRFI);
		});
		this.manualRfiCostRequestService
			.saveCosts(costs, this.rfiRequest.rfiPrefix, this.rfiRequest.rfiNum)
			.subscribe((response) => {
				console.log(response);
				//this.messageService.add({life: 300000, severity: 'success', summary: 'Success', detail: 'RFI record is updated'});
			});
		this.edtCosts.forEach((cost) => {
			cost.prevCost = parseFloat(cost.prevCost);
			cost.cost = parseFloat(cost.cost);
		});

		this.edtCosts[0].finance = this.commercialCosts[0].lsIncludeFIn;
		this.rfiEDTCostService
			.saveEDTCosts(
				this.edtCosts,
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum
			)
			.subscribe((response) => {
				console.log(response);
				//this.messageService.add({life: 300000, severity: 'success', summary: 'Success', detail: 'RFI record is updated'});
			});
		this.edtCosts.forEach((cost) => {
			cost.prevCost = parseFloat(cost.prevCost);
			cost.cost = parseFloat(cost.cost);
		});
		//}
	}

	get4Digit(cost) {
		// Convert string input to number if necessary
		const numericCost = typeof cost === 'string' ? parseFloat(cost) : cost;

		// Check if the conversion was successful or if it's a valid number
		if (cost === null || isNaN(numericCost)) {
			return '0.0000';
		}

		// Format the number to 5 decimal places and return it
		let amount = numericCost.toFixed(5);
		amount = parseFloat(amount).toLocaleString('en-US');

		return amount;
	}

	getToolingCost() {
		let toolingCost = 0;

		if (this.isToolingWholeOption === 'true') {
			toolingCost = this.rfiRequest.rfiCost;
		} else {
			toolingCost = this.toolingTotal;
		}

		return toolingCost;
	}

	saveToolings() {
		//if (this.prevToolingCost !== this.getToolingCost()) {
		this.costChange = true;
		this.rfiToolingRequestService
			.saveToolings(
				this.toolingRecords,
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum
			)
			.subscribe((response) => {
				console.log(response);
			});

		//}
	}

	onTabChange(event) {
		this.tabIndex = event.index;
	}

	isCostTab() {
		return this.tabIndex === 0 || this.tabIndex === 1;
	}

	isEditCost() {
		return this.selectedMode === 'Edit';
	}

	isEditMode() {
		return (
			(this.isAdminRole() || this.buyerSupplier === 'buyer') &&
			this.rfiRequest.statusId === 12 &&
			this.isOwnRfi()
		);
		//&& (this.rfiRequest.statusId !== 8 && this.rfiRequest.statusId !== 9 && this.rfiRequest.statusId !== 10);
	}

	isInternalCostEstimatorRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const cdsid = sessionStorage.getItem('cdsid');
		return (
			this.rfiRequest.statusId === 12 ||
			(cdsid === this.rfiRequest.costEstimator &&
				(apsRoles.includes('CostEstimator') ||
					apsRoles.includes('JV Costestimator')))
		);
	}

	internalCostEstimate() {
		return !(
			(this.isCostEstimatorRole() ||
				this.isAdmin() ||
				this.rfiRequest.statusId === 12) &&
			this.canInteranlCostDetailsEdit(this.interanlCostDetailsEdit)
		);
	}

	internalCostTarget() {
		return !(
			(this.isAdmin() || this.rfiRequest.statusId === 12) &&
			this.canInteranlCostDetailsEdit(this.interanlCostDetailsEdit)
		);
	}

	canInteranlCostDetailsEdit(flag) {
		const statusIds = [1, 2, 3, 4, 5, 6, 15, 12];
		return (
			this.buyerSupplier === 'buyer' &&
			flag &&
			statusIds.includes(this.rfiRequest.statusId)
		);
	}

	getInterCostDetailsUpdateString() {
		const updateDate = moment(
			this.rfiRequest.updateIntCstDetailsDate
		).format('MM/DD/YYYY HH:MM');
		return (
			'Updated By' +
			'-' +
			this.rfiRequest.updateIntCstDetailsBy +
			'--' +
			this.rfiRequest.role +
			'--' +
			updateDate
		);
	}

	validateCurrency(value: string): void {
		const uppercaseValue = value.replace(/[^A-Z]/g, '').toUpperCase();
		this.rfiRequest.partCostTargetCur = uppercaseValue;
	}

	saveInternalCotDetails() {
		this.toggleInteranlCostDetailsEdit(false);

		this.rfiRequest.role = JSON.parse(
			sessionStorage.getItem('apsRoles')
		)[0];
		this.rfiRequest.toolType = this.isToolType();
		this.rfiRequest.createdBy = sessionStorage.getItem('userId');
		this.rfiRequest.updateIntCstDetailsBy =
			sessionStorage.getItem('userId');
		this.rfiRequest.partCostTarget = Number(this.rfiRequest.partCostTarget);
		this.rfiRequest.pdMatCostEstimate = Number(
			this.rfiRequest.pdMatCostEstimate
		);
		this.rfiRequest.e3pMatCostEstimate = Number(
			this.rfiRequest.e3pMatCostEstimate
		);
		this.rfiRequest.toolingCostTarget = Number(
			this.rfiRequest.toolingCostTarget
		);
		this.rfiRequest.pdToolingEstimate = Number(
			this.rfiRequest.pdToolingEstimate
		);
		this.rfiRequest.e3pToolingEstimate = Number(
			this.rfiRequest.e3pToolingEstimate
		);
		this.rfiRequest.rfiPrevCost = Number(this.rfiRequest.rfiPrevCost);
		this.rfiRequest.updateIntCstDetailsDate = moment();
		this.rfiRequest.rfiCost = Number(this.rfiRequest.rfiCost);
		this.manualRfiRequestService
			.saveRFIInternalCostDetails(this.rfiRequest)
			.subscribe((res) => {
				console.log(res);
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'success',
						summary:
							'Manual RFI Internal Cost details has been saved.'
					});
				}, 30);
			});
	}

	cancelInternalCostDetails() {
		this.toggleInteranlCostDetailsEdit(false);
		if (this.mode === 'edit') {
			this.isLoader = true;
			this.fetchRFIHeaderData();
		} else {
			this.rfiRequest.postfdjF = true;
			this.rfiRequest.privateRFI = false;
			this.initializeRFICosts(this.rfiRequest);
			this.retriveAllCosts(
				this.rfiRequest.rfiNum,
				this.rfiRequest.prevRfiNum
			);
			this.setDefaultCDSId();
		}
	}
	validateEmail(email) {
		const regexpEmail = new RegExp(
			'[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
		); // NOSONAR
		if (email) {
			email = email.toLowerCase();
		}
		return regexpEmail.test(email);
	}

	validateManualRFIFields() {
		const regexpCode = new RegExp('^[A-Za-z0-9]*$');

		if (
			!this.rfiRequest.buyerCode ||
			!regexpCode.test(this.rfiRequest.buyerCode.trim())
		) {
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Warning',
				detail: 'Please enter valid Buyer CDSID.'
			});

			return false;
		}

		const regexpEmail = new RegExp(
			'[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
		); // NOSONAR

		if (
			!this.rfiRequest.supplier ||
			!regexpEmail.test(this.rfiRequest.supplier.trim())
		) {
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Warning',
				detail: 'Please enter valid email address for Supplier Email.'
			});

			return false;
		}

		if (
			this.rfiRequest.secondSupplier &&
			!regexpEmail.test(this.rfiRequest.secondSupplier.trim())
		) {
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Warning',
				detail: 'Please enter valid Supplier Engg. Email.'
			});

			return false;
		}

		if (
			this.rfiRequest.drCDSID &&
			!regexpCode.test(this.rfiRequest.drCDSID.trim())
		) {
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Warning',
				detail: 'Please enter valid D&R CDSID.'
			});

			return false;
		}

		return true;
	}

	isShowResubmitBanner() {
		return this.rfiRequest.rfiResubmitCount > 0;
	}

	getResubmitBanner() {
		return `Version #${this.rfiRequest.rfiResubmitCount}`;
	}

	getHistoryCosts() {
		const pieceDelta = this.subMatTotCurRFI - this.subMatTotPrevRFI;
		const comDelta = this.subComTotCurRFI - this.subComTotPrevRFI;
		const toolDelta = this.getToolingCost() - this.rfiRequest.rfiPrevCost;
		let lumpSumCost = 0;
		let prevRfiLumpSumA = 0;
		for (const result of this.edtCosts) {
			lumpSumCost = result.cost;
			prevRfiLumpSumA = result.prevCost;
		}

		const lumpSumDelta = lumpSumCost - prevRfiLumpSumA;

		const historyCosts = {
			pieceCost: this.subMatTotCurRFI,
			pieceDelta,
			comCost: this.subComTotCurRFI,
			comDelta,
			toolCost: this.getToolingCost(),
			toolDelta,
			lumpSumCost,
			lumpSumDelta
		};

		return {
			rfiPrefix: this.rfiRequest.rfiPrefix,
			rfiId: this.rfiRequest.rfiNum,
			piecePrice: historyCosts.pieceCost,
			piecePriceDelta: historyCosts.pieceDelta,
			commPrice: historyCosts.comCost,
			commPriceDelta: historyCosts.comDelta,
			toolingPrice: historyCosts.toolCost,
			toolingPriceDelta: historyCosts.toolDelta,
			lumpSumPrice: parseInt(historyCosts.lumpSumCost.toString(), 10),
			lumSumPriceDelta: historyCosts.lumpSumDelta,
			partPrefix: this.rfiRequest.partPrefix,
			partBase: this.rfiRequest.partBase,
			partSuffix: this.rfiRequest.partSuffix,
			plant: this.rfiRequest.plantCode,
			version: this.rfiRequest.rfiResubmitCount
		};
	}

	isToolType() {
		return (
			this.rfiRequest.toolType === 'true' ||
			this.rfiRequest.toolType === true
		);
	}

	generatePdf() {
		this.pdfGenerator.generatePdf(
			this.rfiRequest,
			this.getStatusString(),
			this.materialCosts,
			this.commercialCosts,
			this.isToolingWholeOption,
			this.toolingTotal,
			this.totCurvRFI,
			this.totPrevRFI,
			this.rfiHistoryList,
			this.buyerSupplier,
			'RFI'
		);
	}

	private setDefaultCDSId() {
		if (this.isBuyerRole()) {
			this.rfiRequest.buyerCode = sessionStorage.getItem('userId');
		}

		if (this.isPdEnggRole()) {
			this.rfiRequest.drCDSID = sessionStorage.getItem('userId');
		}
	}

	private initializeRFICosts(rfiRequest) {
		rfiRequest.partCostTarget = this.get4Decimal(0);
		rfiRequest.partCostTargetCur = 'USD';
		rfiRequest.pdMatCostEstimate = this.get4Decimal(0);
		rfiRequest.pdMatCostEstimateCur = 'USD';
		rfiRequest.e3pMatCostEstimate = this.get4Decimal(0);
		rfiRequest.e3pMatCostEstimateCur = 'USD';
		rfiRequest.toolingCostTarget = this.get4Decimal(0);
		rfiRequest.toolingCostTargetCur = 'USD';
		rfiRequest.pdToolingEstimate = this.get4Decimal(0);
		rfiRequest.pdToolingEstimateCur = 'USD';
		rfiRequest.e3pToolingEstimate = this.get4Decimal(0);
		rfiRequest.e3pToolingEstimateCur = 'USD';
		rfiRequest.rfiComments = '';
		rfiRequest.buyerComments = '';
		rfiRequest.pdComments = '';
		rfiRequest.internalComment = '';
		rfiRequest.rfiPrevCost = this.get4Decimal(0);
		rfiRequest.rfiCost = 0;
	}

	private addWeekdays(date, days) {
		date = moment(date); // use a clone
		while (days > 0) {
			date = date.add(1, 'days');
			// decrease "days" only if it's a weekday.
			if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
				days -= 1;
			}
		}
		return date;
	}

	private getSundayMidNightTime(time) {
		const isWeekend = time.isoWeekday() === 6 || time.isoWeekday() === 7;
		if (isWeekend) {
			const daysForFridayCalc = time.isoWeekday() === 7 ? 0 : 1;
			time = time.endOf('day').add(daysForFridayCalc, 'days');
		}

		return moment.utc(time);
	}

	private calculateTimerTime(rfiRequest) {
		this.negativeTimer = null;
		const isSupplier = this.buyerSupplier === 'supplier' ? true : false;
		let time = 3;
		if (this.timerOption) {
			time = Number(this.timerOption.value);
		}
		const days = isSupplier ? time : 2;

		this.additionTimerRqstMsg =
			'Design Change cost estimates to be returned within ' +
			days * 24 +
			' hours. If you require additional information or time, please contact your Ford Buyer and/or Engineer.';

		if (rfiRequest.responseDate) {
			const isWeekend =
				moment.utc(rfiRequest.responseDate).isoWeekday() === 6 ||
				moment.utc(rfiRequest.responseDate).isoWeekday() === 7;
			let endTime = this.addWeekdays(
				moment.utc(rfiRequest.responseDate),
				days
			);
			if (isWeekend) {
				endTime = this.getSundayMidNightTime(
					moment(rfiRequest.responseDate)
				);
				endTime = this.addWeekdays(moment.utc(endTime), days);
			}
			const startTime = this.getSundayMidNightTime(moment(new Date()));

			let daysDiff = endTime.diff(moment.utc(startTime), 'days');
			if (daysDiff > days) {
				daysDiff = daysDiff - 2;
			}

			const dif = moment.duration(endTime.diff(startTime));
			const hours = daysDiff * 24 + dif.hours();
			this.timer = [hours, dif.minutes(), dif.seconds()].join(':');

			this.negativeTimer =
				endTime.isBefore(startTime) || endTime === startTime;

			if (this.isShowReqAddTime() && this.negativeTimer) {
				this.modeType = [];
			}
		}
	}

	private calculateTimerTimeBack(rfiRequest) {
		if (rfiRequest.responseDate) {
			const endTime = moment.utc(rfiRequest.responseDate).add(3, 'days');
			const startTime = moment.utc(new Date());
			const dif = moment.duration(endTime.diff(startTime));

			const hours = dif.days() * 24 + dif.hours();
			this.timer = [hours, dif.minutes(), dif.seconds()].join(':');

			this.negativeTimer = endTime.isBefore(startTime);

			if (this.isEditRFI() && this.negativeTimer) {
				this.modeType = [
					{
						title: 'View',
						label: 'View',
						value: 'View',
						icon: 'fa fa-eye'
					}
				];
			}
		}
	}

	private requestAdditionalTime() {
		this.manualRfiRequestService
			.updateExtReqCount(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum,
				this.buyerSupplier === 'supplier'
			)
			.subscribe((response) => {
				/*this.messageService.add({
					life: 300000, severity: 'success',
					summary:
						'Extra time has been allocated successfully to RFI request.'
				});*/
				this.rfiRequest = response;

				if (this.isShowReqAddTime()) {
					this.calculateTimerTime(this.rfiRequest);
				}

				if (this.isEditRFI() && !this.negativeTimer) {
					this.updateModType();
				}
			});
	}

	private retriveAllCostsForSelecedRFI(rfiId, prevRfiNum) {
		this.rfiCostRequestService
			.findByRfiHeaderId(rfiId, prevRfiNum)
			.subscribe((responseCosts) => {
				const rfiCost = responseCosts;

				this.materialCosts = rfiCost.filter(
					(res) => res.rfiType === 'Material'
				);
				this.commercialCosts = rfiCost.filter(
					(res) => res.rfiType === 'Commercial'
				);

				this.materialCosts = this.materialCosts.map((res) => {
					res.prevRFI = this.get4Decimal(0);
					res.curRFI = this.get4Decimal(0);
					return res;
				});
				this.commercialCosts = this.commercialCosts.map((res) => {
					res.prevRFI = this.get4Decimal(0);
					res.curRFI = this.get4Decimal(0);
					return res;
				});

				this.updateTotalCost(true);
				this.isLoader = false;
			});
	}

	private retriveAllCosts(rfiId, prevRfiNum) {
		this.manualRfiCostRequestService
			.findByRfiHeaderId(rfiId, prevRfiNum)
			.subscribe((responseCosts) => {
				const rfiCost = responseCosts;

				this.materialCosts = rfiCost.filter(
					(res) => res.rfiType === 'Material'
				);
				this.commercialCosts = rfiCost.filter(
					(res) => res.rfiType === 'Commercial'
				);

				this.materialCosts = this.materialCosts.map((res) => {
					//res.prevRFI = this.get4Decimal(res.prevRFI);
					//res.curRFI = this.get4Decimal(res.curRFI);
					return res;
				});

				this.commercialCosts = this.commercialCosts.map((res) => {
					//res.prevRFI = this.get4Decimal(res.prevRFI);
					//res.curRFI = this.get4Decimal(res.curRFI);
					return res;
				});

				this.rfiEDTCostService
					.findByRfiHeaderId(
						this.rfiRequest.rfiPrefix,
						this.rfiRequest.rfiNum
					)
					.subscribe((response) => {
						this.edtCosts = response;
						this.edtCosts.forEach((cost) => {
							if (
								cost.finance === true ||
								cost.finance === false
							) {
								cost.finance = cost.finance ? 'true' : 'false';
							} else {
								cost.finance = null;
							}
						});

						if (this.edtCosts.length === 1 && this.edtCosts[0]) {
							//this.edtCosts[0].prevCost = this.get4DigitFixed(
							//	this.edtCosts[0].prevCost
							//);
							//this.edtCosts[0].cost = this.get4DigitFixed(
							//	this.edtCosts[0].cost
							//);

							this.commercialCosts = this.commercialCosts.map(
								(res) => {
									if (
										res.costBucket ===
										'ED&T paid in Piece Price'
									) {
										const edtCostFinance =
											this.edtCosts[0].finance;
										const finance = res.lsIncludeFIn;
										res.lsIncludeFIn = edtCostFinance;
										this.edtCosts[0].finance = finance;
									}
									return res;
								}
							);
						}
						this.updateTotalCost(true);
						this.isLoader = false;
					});
			});
	}

	private retriveTooling() {
		this.rfiToolingRequestService
			.findByRfiHeaderId(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum
			)
			.subscribe((records) => {
				this.toolingRecords = records;
				if (this.toolingRecords && this.toolingRecords.length > 0) {
					this.isToolingWholeOption = 'false';
					this.rfiRequest.rfiCost = 0;
				}
				this.getTotalTooling();
			});
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe((params) => {
			this.buyerSupplier = params.get('buyerSupplier');
			this.mode = params.get('mode');
			this.rfiId = params.get('id');
			this.searchRfi = params.get('searchRfi');
			this.back = params.get('back');
			if (params.get('status')) {
				this.status = params.get('status');
			}

			if (params.get('program')) {
				this.program = params.get('program');
			}

			if (params.get('new')) {
				this.new = params.get('new');
			}

			this.loadRFIData();
		});
	}

	private calculateTotalCost(costs) {
		let subTotPrevRFI = 0;
		let subTotCurRFI = 0;
		for (const result of costs) {
			if (result.costBucket !== 'Subtotal Piece cost') {
				subTotPrevRFI += result.prevRFI;
				subTotCurRFI += result.curRFI;
			} else {
				result['prevRFI'] = subTotPrevRFI;
				result['curRFI'] = subTotCurRFI;
			}
		}
	}

	private updateTotalCost(initialLoad) {
		this.totPrevRFI = 0;
		this.totCurvRFI = 0;
		let matTotPrevRFI = 0;
		let matTotCurRFI = 0;
		let comTotPrevRFI = 0;
		let comTotCurRFI = 0;

		this.totPrevRFI = 0;
		this.totCurvRFI = 0;
		for (const result of this.materialCosts) {
			if (!result.isTotal) {
				matTotPrevRFI += +result.prevRFI;
				matTotCurRFI += +result.curRFI;
			}

			if (result.isTotal) {
				result.prevRFI = matTotPrevRFI;
				result.curRFI = matTotCurRFI;
			}
		}

		this.subMatTotCurRFI = matTotCurRFI;
		this.subMatTotPrevRFI = matTotPrevRFI;

		if (initialLoad) {
			this.materialCosts.push({
				costBucket: 'Piece Price Subtotal',
				prevRFI: matTotPrevRFI,
				curRFI: matTotCurRFI,
				isTotal: true,
				lookupId: -1
			});
			this.prevSubMatTotCurRFI = matTotCurRFI;
			this.prevSubMatTotPrevRFI = matTotPrevRFI;
		}

		for (const result of this.commercialCosts) {
			if (!result.isTotal) {
				comTotPrevRFI += +result.prevRFI;
				comTotCurRFI += +result.curRFI;
			}

			if (result.isTotal) {
				result.prevRFI = comTotPrevRFI;
				result.curRFI = comTotCurRFI;
			}
		}

		this.subComTotCurRFI = comTotCurRFI;
		this.subComTotPrevRFI = comTotPrevRFI;
		if (initialLoad) {
			this.commercialCosts.push({
				costBucket: 'Commercial Adjustment Total',
				prevRFI: comTotPrevRFI,
				curRFI: comTotCurRFI,
				isTotal: true,
				lookupId: -1
			});

			this.prevSubComTotCurRFI = comTotCurRFI;
			this.prevSubComTotPrevRFI = comTotPrevRFI;
		}

		this.totPrevRFI = matTotPrevRFI + comTotPrevRFI;
		this.totCurvRFI = matTotCurRFI + comTotCurRFI;
		if (initialLoad) {
			for (const result of this.edtCosts) {
				this.prevLumpSumCost = result.cost;
			}
		}
	}

	isInprogress() {
		//const status = (this.buyerSupplier === 'supplier' ? this.rfiRequest.supplierStatus : this.rfiRequest.buyerStatus);
		return this.rfiRequest.statusId === 12 && this.isOwnRfi();
	}

	private responseSubmitted() {
		//const status = (this.buyerSupplier === 'supplier' ? this.rfiRequest.supplierStatus : this.rfiRequest.buyerStatus);
		return (
			this.rfiRequest.statusId === 15 ||
			this.rfiRequest.statusId === 16 ||
			this.rfiRequest.statusId === 7 ||
			this.rfiRequest.statusId === 4 ||
			this.rfiRequest.statusId === 3 ||
			this.rfiRequest.statusId === 3
		);
	}

	private onSubmitRFI() {
		if (!this.isMandatoryFields()) {
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Warning',
				detail: 'Please make sure to enter mandatory RFI fields before Submitting.'
			});
			return;
		}

		if (!this.validateManualRFIFields()) {
			return;
		}
		if (this.isValidBuyer || this.isValidDnR || this.isValidCostEstimator) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Waning',
					detail: 'Fix the error before submitting.'
				});
			}, 30);
		} else {
			this.confirmationService.confirm({
				message: 'Are you sure you want to "Submit RFI" data',
				header: 'Confirmation',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.saveRFI(1, false);
					setTimeout(() => {
						this.messageService.add({
							life: 300000,
							severity: 'success',
							summary: 'Manual RFI data submitted successfully.'
						});
					}, 2000);
				}
			});
		}
	}

	private onSaveAsDraft() {
		if (!this.isMandatoryFields()) {
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Warning',
				detail: 'Please make sure to enter mandatory RFI fields before Saving.'
			});
			return;
		}

		if (!this.validateManualRFIFields()) {
			return;
		}

		this.saveRFI(12, false);
		setTimeout(() => {
			this.messageService.add({
				life: 300000,
				severity: 'success',
				summary: 'Manual RFI data Saved successfully.'
			});
		}, 2000);
		// }
		// });
	}

	private accept() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to "Accept RFI" data',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.saveRFI(8, false);
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'success',
						summary: 'Manual RFI Accepted successfully.'
					});
				}, 2000);
			}
		});
	}

	private reject() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to "Reject RFI" data',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.saveRFI(9, false);
			}
		});
	}

	private cancelled() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to "Cancel RFI" data',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.saveRFI(10, false);
			}
		});
	}

	private closed() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to "Close RFI" data',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.saveRFI(16, false);
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'success',
						summary: 'Manual RFI closed successfully.'
					});
				}, 2000);
			}
		});
	}

	private saveRFI(status, isSubmit) {
		if (!this.validateManualRFIFields()) {
			return;
		}

		if (status === 1) {
			this.rfiRequest.supplierStatus = 'INITIAL RFI REQUIRED';
			this.rfiRequest.buyerStatus = 'UNREAD';
			this.rfiRequest.statusId = 1;
		}

		if (status === 12) {
			this.rfiRequest.supplierStatus = 'IN PROCESS';
			this.rfiRequest.buyerStatus = 'IN PROCESS';
			this.rfiRequest.statusId = 12;
		}

		if (status === 8) {
			this.rfiRequest.supplierStatus = 'ACCEPTED';
			this.rfiRequest.buyerStatus = 'ACCEPTED';
			this.rfiRequest.statusId = 8;
		}

		if (status === 9) {
			this.rfiRequest.supplierStatus = 'REJECTED';
			this.rfiRequest.buyerStatus = 'REJECTED';
			this.rfiRequest.statusId = 9;
		}

		if (status === 10) {
			this.rfiRequest.supplierStatus = 'CANCELLED';
			this.rfiRequest.buyerStatus = 'CANCELLED';
			this.rfiRequest.statusId = 10;
		}

		if (status === 15) {
			this.rfiRequest.supplierStatus = 'RESPONSE SUBMITTED';
			this.rfiRequest.buyerStatus = 'REVIEW SUPPLIER RESPONSE';
		}

		if (status === 16) {
			this.rfiRequest.supplierStatus = 'CLOSED';
			this.rfiRequest.buyerStatus = 'CLOSED';
			this.rfiRequest.statusId = 16;
		}

		this.rfiRequest.createdBy = sessionStorage.getItem('userId');
		this.rfiRequest.updateBy = sessionStorage.getItem('userId');
		this.rfiRequest.timer = this.timerOption.value;

		this.rfiRequest.toolType = this.isToolType();

		this.rfiRequest.partCostTarget = parseFloat(
			this.rfiRequest.partCostTarget
		);
		this.rfiRequest.pdMatCostEstimate = parseFloat(
			this.rfiRequest.pdMatCostEstimate
		);
		this.rfiRequest.e3pMatCostEstimate = parseFloat(
			this.rfiRequest.e3pMatCostEstimate
		);
		this.rfiRequest.toolingCostTarget = parseFloat(
			this.rfiRequest.toolingCostTarget
		);
		this.rfiRequest.pdToolingEstimate = parseFloat(
			this.rfiRequest.pdToolingEstimate
		);
		this.rfiRequest.e3pToolingEstimate = parseFloat(
			this.rfiRequest.e3pToolingEstimate
		);

		this.rfiRequest.rfiPrevCost = parseFloat(this.rfiRequest.rfiPrevCost);
		this.rfiRequest.rfiCost = parseFloat(this.rfiRequest.rfiCost);
		this.rfiRequest.psa = parseInt(this.rfiRequest.psa, 10);

		this.rfiRequest.prevRfiPrefix = 'I';

		let role = '';

		if (this.isBuyerRole()) {
			role = 'Buyer';
		}

		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles.includes('EDCM_UPDATE')) {
			role = 'EDCM_UPDATE';
		}

		if (this.isPdEnggRole()) {
			role = 'PD_Engineer';
		}

		if (this.isCostEstimatorRole()) {
			role = 'CostEstimator';
		}

		if (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			this.isEDCMViewOnlyRole()
		) {
			role = 'eDCM_IT_ADMIN';
		}

		this.rfiRequest.role = role;

		this.manualRfiRequestService
			.saveRFI(this.rfiRequest)
			.subscribe((res) => {
				this.rfiRequest.rfiResubmitCount = res.rfiResubmitCount;
				this.saveCosts();
				this.saveToolings();
				if (res.prodTooling) {
					this.rfiRequest.rfiCost = res.prodTooling;
				}

				this.rfiHistory = this.getHistoryCosts();
				if (this.costChange && isSubmit) {
					this.historyService
						.saveHistory(this.rfiHistory)
						.subscribe((result) => {
							console.log(result);
							this.prevSubMatTotCurRFI = this.subMatTotCurRFI;
							this.prevSubComTotCurRFI = this.subComTotCurRFI;
							this.prevToolingCost = this.getToolingCost();
							// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
							for (const result of this.edtCosts) {
								this.prevLumpSumCost = result.cost;
							}

							this.costChange = false;
							this.getRFIHistoryList(
								this.rfiRequest.rfiPrefix,
								this.rfiRequest.rfiNum
							);
						});
				}

				if (isSubmit) {
					this.updateRFI();
					this.updateStatus(15, 'RESPONSE SUBMITTED', false);
				}
			});
	}

	validationCheck() {
		this.buddyBuyerMap['buddyBuyer'] =
			this.rfiRequest.buyerCode !== undefined
				? this.rfiRequest.buyerCode.trim()
				: '';
		this.manualRfiRequestService
			.validateBuyer(this.buddyBuyerMap)
			.subscribe((response) => {
				if (response.status === 'Success') {
					this.isBuyerId = true;
					this.validationMessage = response.validationMessage;
					if (response.validationMessage === 'CDSID is invalid') {
						this.isValidBuyer = true;
					} else {
						this.isValidBuyer = false;
					}
				} else {
					this.isBuyerId = false;

					this.isValidBuyer = false;
				}
			});
	}
	validationCheckCost() {
		this.costEstimatorMap['buddyBuyer'] =
			this.rfiRequest.costEstimator !== undefined
				? this.rfiRequest.costEstimator.trim()
				: '';
		this.manualRfiRequestService
			.validateCostEstimator(this.costEstimatorMap)
			.subscribe((response) => {
				if (response.status === 'Success') {
					this.isCostEstimatorId = true;
					this.validationMessage = response.validationMessage;
					if (response.validationMessage === 'CDSID is invalid') {
						this.isValidCostEstimator = true;
					} else {
						this.isValidCostEstimator = false;
					}
				} else {
					this.isCostEstimatorId = false;

					this.isValidCostEstimator = false;
				}
			});
	}
	validationCheckEngineer(inputValue) {
		if (this.isPdEnggRole() && inputValue === false) {
			this.buddyEnginnerMap['buddyBuyer'] =
				sessionStorage.getItem('userId');
		} else {
			this.buddyEnginnerMap['buddyBuyer'] = this.rfiRequest.drCDSID;
		}

		this.manualRfiRequestService
			.validateEngineer(this.buddyEnginnerMap)
			.subscribe((response) => {
				if (response.status === 'Success') {
					this.isPDId = true;
					this.validationMessagePD = response.validationMessage;
					if (response.validationMessage === 'CDSID is invalid') {
						this.isValidDnR = true;
					} else {
						this.isValidDnR = false;
					}
				} else {
					this.isPDId = false;
					this.isValidDnR = false;
				}
			});
	}
}
